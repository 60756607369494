import { flow, Instance, types } from 'mobx-state-tree';

import { UrlHelper } from '../../../../../infrastructure/redirecting/UrlHelper';
import {
  TransactionStatusViewModel,
  ValidateCreditCardAsyncResponseViewModel,
  ValidateCreditCardAsyncStatus
} from '../../../../../external/shared/api/EnrollmentClient.generated';
import { getRootStore } from '../../../../root/RootStoreUtils';
import { apiClient } from '../../../model/credit-card/CreditCard';

export const AsyncPaymentStore = types
  .model('AsyncPaymentStore', {
    show3dsWarningModal: types.optional(types.boolean, false),
    validationResponse: types.maybe(types.frozen<ValidateCreditCardAsyncResponseViewModel>())
  })
  .volatile(() => ({
    threeDsWarningDeferred: undefined as (() => void) | undefined
  }))
  .actions(self => ({
    show3dsWarning(): Promise<void> {
      self.show3dsWarningModal = true;
      return new Promise(resolve => {
        self.threeDsWarningDeferred = resolve;
      });
    },
    close3dsWarning() {
      self.show3dsWarningModal = false;
      if (self.threeDsWarningDeferred) {
        self.threeDsWarningDeferred();
      }
      self.threeDsWarningDeferred = undefined;
    }
  }))
  .actions(self => ({
    validateCreditCardAsync: flow(function* validateCreditCardAsync(): any {
      const rootStore = getRootStore(self);
      const { billingEntry, threeDSecureEntry } = rootStore.moduleStores;
      const { requestId, creditCardData } = billingEntry!;

      if (threeDSecureEntry!.config.multiple3dsEnabled) {
        yield creditCardData.resolveGateway();
      }

      self.validationResponse = yield apiClient.validateCreditCard({
        creditCard: {
          ...creditCardData.getDataToSubmit(),
          clientJsonValue: yield threeDSecureEntry!.auth()
        },
        merchantSiteUrl: new UrlHelper().toVoUrl('self-closing-page'),
        requestId
      });
      const {
        validateCreditCardAsyncStatus,
        redirectionUrl,
        transactionId,
        paymentMethodIdentity,
        paymentMethodProviderType
      } = self.validationResponse!;

      let transactionStatus: TransactionStatusViewModel = {
        id: transactionId,
        status: validateCreditCardAsyncStatus
      };
      if (validateCreditCardAsyncStatus === ValidateCreditCardAsyncStatus.Pending) {
        if (redirectionUrl) {
          yield self.show3dsWarning();
          window.open(redirectionUrl);
          transactionStatus = yield pollTransactionStatus(transactionId, paymentMethodIdentity, 60);
        } else {
          transactionStatus = yield pollTransactionStatus(transactionId, paymentMethodIdentity, 5);
        }
      }

      if (transactionStatus.status !== ValidateCreditCardAsyncStatus.Succeed)
        throw new Error(
          `ValidateCreditCardAsync failed with status ${transactionStatus.status}, transactionId ${transactionId}`
        );

      return {
        transactionId,
        paymentMethodProviderType
      };
    })
  }));

async function pollTransactionStatus(
  transactionId: string,
  paymentMethodIdentity: string | undefined,
  maxAttempt: number
): Promise<TransactionStatusViewModel> {
  const fetcher = () => apiClient.getTransactionStatus(transactionId, paymentMethodIdentity);

  const breakCondition = (status: TransactionStatusViewModel) =>
    status.status !== ValidateCreditCardAsyncStatus.Pending;
  const status = await poll<TransactionStatusViewModel>(fetcher, breakCondition, maxAttempt);
  return status;
}

async function poll<T>(
  fetcher: () => Promise<T>,
  breakCondition: (p: T) => boolean,
  maxAttempt: number,
  count = 0
): Promise<T> {
  const interval = 3000;
  let pollingResult = await fetcher();
  let continuePolling = !breakCondition(pollingResult) && count < maxAttempt;
  while (continuePolling) {
    count++;
    await new Promise(resolve => setTimeout(resolve, interval));
    pollingResult = await fetcher();
    continuePolling = !breakCondition(pollingResult) && count < maxAttempt;
  }
  return pollingResult;
}

export type AsyncPaymentStore = Instance<typeof AsyncPaymentStore>;
