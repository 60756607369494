import { Instance, types, resolveIdentifier, flow, getSnapshot} from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { Currency } from '../../../reference/Currency';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { AddressEntryFormData } from '../../address/main-address/AddressEntryFormData';
import { ShowPickupPointResult } from '../../external/PickupPointModalStore';
import { getRootStore } from '../../root/RootStoreUtils';
import { DeliveryTime } from '../../../reference/DeliveryTime';
import { ShiptoShopStore } from './ShiptoShopStore';

export const ShippingMethod = types.model('ShippingMethod', {
  id: types.identifierNumber,
  name: types.string,
  carrierId: types.maybe(types.number),
  carrierName: types.string,
  rate: types.number,
  currency: types.reference(Currency),
  estimatedDelivery: types.optional(types.string, ''),
  isWillCall: types.boolean,
  isAusPost: types.boolean,
  isPickupPoint: types.boolean,
  isShiptoShop:types.optional(types.boolean,false),
  willCallAddress: types.maybe(AddressEntryFormData),
  deliveryTimes: types.array(DeliveryTime)
});

export const ItemRestrictedByLocation = types.model('ItemRestrictedByLocation', {
  id: types.identifierNumber,
  name: types.string
});
export type ShippingMethod = Instance<typeof ShippingMethod>;

export const ShippingMethodSelectionStore = types
  .model({
    shippingMethods: types.array(ShippingMethod),
    shippingMethodsLoading: types.optional(types.boolean, false),
    selectedShippingMethod: types.maybe(types.reference(ShippingMethod)),
    selectedDeliveryTimeId: types.maybe(types.number),
    itemsRestrictedByLocation: types.array(ItemRestrictedByLocation),
    hasDangerousGoods: types.boolean,
    hasCbdGoods: types.boolean,
    isInRestrictedArea: types.boolean,
    storeChangeWarning: types.optional(types.number, 0),
    noShippingItems: types.boolean,
    noShippingMethods: false,
    shipToShop:types.maybe(ShiptoShopStore)
  })
  .views(self => ({
    get showDangerousGoodsWarning() {
      return self.hasDangerousGoods && self.isInRestrictedArea;
    },
    get showCbdGoodsWarning() {
      return self.hasCbdGoods && self.noShippingMethods;
    },
    get showRestrictedsGoodsWarning() {
      return self.itemsRestrictedByLocation && self.itemsRestrictedByLocation.length > 0;
    },
    get itemNamesRestrictedByLocation() {
      return self.itemsRestrictedByLocation ? self.itemsRestrictedByLocation.map(x => x.name) : [];
    },
    get showWarning() {
      return (
        self.storeChangeWarning > 0 ||
        this.showRestrictedsGoodsWarning ||
        this.showDangerousGoodsWarning ||
        this.showCbdGoodsWarning ||
        self.noShippingMethods
      );
    },
    get supportsDisplay() {
      return true;
    },
    get reactForm() {
      const selectedShippingMethodId = self.selectedShippingMethod ? self.selectedShippingMethod.id : '';
      const selectedDeliveryTimeId = self.selectedDeliveryTimeId;
      return new FormState({
        shippingMethod: new FieldState(selectedShippingMethodId).validators(required()),
        deliveryTime: new FieldState(selectedDeliveryTimeId)
      });
    },
    getDataToSubmit(): any {
      return {
        shippingId: Number(this.reactForm.$.shippingMethod.value),
        deliveryTimeId: this.reactForm.$.deliveryTime.value,
        willCallAddress: self.selectedShippingMethod && self.selectedShippingMethod.willCallAddress,
        ausPostAddress: getRootStore(self)?.moduleStores?.shippingAddressEntry?.ausPostAddress,
        shipToShopAddress: getRootStore(self)?.moduleStores?.shippingAddressEntry?.shipToShopAddress
      };
    },
    canProceed() {
      return !this.reactForm.hasError && !this.showRestrictedsGoodsWarning;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    clearShippingMethods(differentStoreAlert: number) {
      self.selectedShippingMethod = undefined;
      self.selectedDeliveryTimeId = undefined;
      self.shippingMethods.clear();
      this.updateWarningState(differentStoreAlert);
    },
    updateWarningState(differentStoreAlert: number) {
      self.storeChangeWarning = differentStoreAlert;
      self.noShippingMethods = false;
    },
   
    changeShippingMethod: flow(function* changeShippingMethod(shippingMethodId: number): any {
      const root = getRootStore(self);
      const addressEntry = root.moduleStores.shippingAddressEntry;
      const shippingMethod = resolveIdentifier(ShippingMethod, self, shippingMethodId);
      self.selectedShippingMethod = shippingMethod;
      self.selectedDeliveryTimeId = undefined;
      if (!addressEntry) {
        return;
      }
      if (shippingMethod && shippingMethod.isWillCall) {
        addressEntry.setShippingToWillCallAddress(shippingMethod.willCallAddress!);
      } else {
        addressEntry.unsetShippingToWillCallAddress();
      }
      if (shippingMethod?.isAusPost) {
        const ausPostConfigurationResponse = yield enrollmentResource.get('aus-post-configuration');
        const primaryPhone = root.moduleStores?.personalInfoReview?.primaryPhone;
        addressEntry.setShowAusPostWidget(true);
        addressEntry.setAusPostAddressState(false);

        (window as any).LocationFinder.init({
          root: 'auspost-widget',
          apiKey: ausPostConfigurationResponse.data.apiKey,
          apiSecret: ausPostConfigurationResponse.data.apiSecret,
          googleApiKey: ausPostConfigurationResponse.data.googleApiKey,
          merchantId: ausPostConfigurationResponse.data.merchantId,
          emailInputId: ausPostConfigurationResponse.data.emailInputId,
          callback: (storeDetails : any) => {
            const { locale } = root.localeManager.language;
            root.reference.getCountries(locale).then(countries => {
              const country = countries?.find(x => x.isoCode2 === "AU");
              const abbreviation = country?.states?.find(x => x.abbreviation === storeDetails.state)?.abbreviation;
              
              addressEntry.setAusPostAddress(AddressEntryFormData.create({
                street1: storeDetails.line1,
                street2: storeDetails.line2,
                city: storeDetails.town,
                postalCode: storeDetails.postcode,
                isAusPost: true,
                stateProv: abbreviation,
                phoneNumber: primaryPhone,
                country: "AU"
              }));
            });
        }
      })
      }
      else{
        addressEntry.unsetAusPostAddress();
        addressEntry.setShowAusPostWidget(false);
      }

      if (shippingMethod && shippingMethod.isPickupPoint) {
        const address: ShowPickupPointResult = yield addressEntry.pickupPointModal.showPickupPointModal();
        if (address) {
          addressEntry.setShippingToPickupPointAddress(address);
        } else {
          addressEntry.unsetShippingToPickupPointAddress();
          self.selectedShippingMethod = undefined;
          self.selectedDeliveryTimeId = undefined;
        }
      } else {
        addressEntry.unsetShippingToPickupPointAddress();
      }
      if(shippingMethod && (shippingMethod.id == 515 || shippingMethod.id == 517)){

        const shippingAddress = addressEntry.shippingAddress;
        const orderId = root.enrollmentStatus.enrollmentId;
        const shipMethod= shippingMethod;
        const shipToShopUrl= root.moduleStores.shipToShopUrl;
        const countryIsoCode3= root.enrollmentStatus.countryIso3;
        
          self.shipToShop?.showPopup(shippingAddress,shipMethod,orderId,shipToShopUrl,countryIsoCode3)
          .then((message: any) => {
            self.shipToShop?.closeOverlay();
            const { locale } = root.localeManager.language;
            root.reference.getCountries(locale).then(countries => {
            const country = countries?.find(x => x.isoCode2 === root.enrollmentStatus.countryIso);
            const abbreviation = country?.states?.find(x => x.abbreviation === message.data.state)?.abbreviation;
            addressEntry.setShipToShopAddress(AddressEntryFormData.create({
              street1: message.data.address,
              street2: '',
              city: message.data.district,
              postalCode: message.data.postalCode,
              isShiptoShop: true,
              stateProv: abbreviation,
              phoneNumber: message.data.phoneNumber,
              country: country?.isoCode2,
              locationId:message.data.locationId
          }));
        });
          return true;
      })
    }
    else{
     addressEntry.unsetShipToShopAddress();
   }
    }),

    changeDeliveryTime(deliveryTimeId: number): any {
      self.selectedDeliveryTimeId = deliveryTimeId;
    },
 
    reQueryShippingMethodsIfNecessary: flow(function* reQueryShippingMethodsIfNecesary(): any {
      if (self.shippingMethods.length === 0) {
        const root = getRootStore(self);
        const shippingAddress = root.moduleStores.shippingAddressEntry;
        if (!shippingAddress) {
          return;
        }
        const address = yield shippingAddress.getSelectedAddress();
        if (address) {
          self.shippingMethodsLoading = true;
          const response = yield enrollmentResource.post('shipping-methods', getSnapshot(address));
          self.shippingMethods = response.data.shippingMethods;
          self.isInRestrictedArea = response.data.isInRestrictedArea;
          self.storeChangeWarning = shippingAddress.hasStoreChangeAlert;
          self.noShippingMethods = self.shippingMethods.length === 0;
          shippingAddress.resetFormDirty();
          self.shippingMethodsLoading = false;
        } else {
          self.isInRestrictedArea = shippingAddress.isInRestrictedArea;
          self.storeChangeWarning = shippingAddress.hasStoreChangeAlert;
          self.noShippingMethods = false;
        }
      }
    }),
    showNoItemsModal(): boolean {
      return self.noShippingItems;
    },
    goToStart(): void {
      const root = getRootStore(self);
      root.stepsManager.goToStep(1);
    }
  })) 
  .actions(self => ({
    afterCreate() {
      const root = getRootStore(self);
      if (root.moduleStores.IsShipToShopEnabled) {
          self.shipToShop = ShiptoShopStore.create();
      }
    }
  }));
export type ShippingMethodSelectionStore = Instance<typeof ShippingMethodSelectionStore>;
