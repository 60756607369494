import type { LocalPayment } from 'braintree-web';
import type { LocalPaymentStartData } from 'braintree-web/local-payment';
import { Instance, types } from 'mobx-state-tree';

import { getRootStore } from '../../../root/RootStoreUtils';
import { enrollmentClient } from '../../../../infrastructure/http/EnrollmentResource';
import { getDecimalPrice } from '../../../../reference/Currency';
import {
  EnrollmentPaymentType,
  VirtualWalletClient,
  VirtualWalletType
} from '../../../../external/shared/api/EnrollmentClient.generated';

const REGEX_PHONE_NUMBER = /[^0-9]/g;

export const apiClient = new VirtualWalletClient('', enrollmentClient);

export const createClient = async (type: VirtualWalletType) => {
  const { client } = await import('braintree-web');
  const token = await apiClient.getAuthorizationToken(type);
  return client.create({ authorization: token });
};

async function initLocalPayment() {
  const { localPayment } = await import('braintree-web');
  const clientInstance = await createClient(VirtualWalletType.IDeal);
  return localPayment.create({ client: clientInstance });
}

export const VirtualWalletStore = types
  .model({})
  .volatile(() => ({
    initLocalPaymentPromise: undefined as Promise<LocalPayment> | undefined
  }))
  .views(self => ({
    get appliedVirtualWalletType(): VirtualWalletType | undefined {
      const rootStore = getRootStore(self);
      switch (rootStore.moduleStores.appliedPaymentsReview?.paymentType) {
        case EnrollmentPaymentType.VirtualWalletIdeal:
          return VirtualWalletType.IDeal;
        case EnrollmentPaymentType.VirtualWalletPaypal:
          return VirtualWalletType.Paypal;
        default:
          return undefined;
      }
    },
    get isEnabled(): boolean {
      return this.appliedVirtualWalletType === VirtualWalletType.IDeal;
    }
  }))
  .actions(self => ({
    async warmUp(): Promise<void> {
      switch (self.appliedVirtualWalletType) {
        case VirtualWalletType.IDeal:
          self.initLocalPaymentPromise ??= initLocalPayment();
          await self.initLocalPaymentPromise;
          break;
        case VirtualWalletType.Paypal:
          return;
        default:
          throw new Error('Virtual Wallet not supported');
      }
    }
  }))
  .actions(self => {
    return {
      async auth(): Promise<void> {
        switch (self.appliedVirtualWalletType) {
          case VirtualWalletType.IDeal:
            await this.authIdealPayment();
            break;
          case VirtualWalletType.Paypal:
            return;
          default:
            throw new Error('Virtual Wallet not supported');
        }
      },
      async authIdealPayment() {
        self.initLocalPaymentPromise ??= initLocalPayment();
        const localPayment: LocalPayment = await self.initLocalPaymentPromise;

        const rootStore = getRootStore(self);

        const currencyCode = rootStore.reference.currencies[0].code;
        const shippingAddress = rootStore.moduleStores.shippingAndBillingReview!.userShippingAddress;
        const personalInfoReview = rootStore.moduleStores.personalInfoReview!;
        const appliedPayments = rootStore.moduleStores.appliedPaymentsReview!;

        let iDealDataPayment: LocalPaymentStartData;
        try {
          await localPayment.startPayment({
            fallback: {
              url: window.location.href,
              buttonText: 'Complete Payment'
            },
            paymentType: 'ideal',
            amount: getDecimalPrice(appliedPayments.amount, currencyCode),
            currencyCode: currencyCode,
            email: personalInfoReview.email,
            phone: personalInfoReview.primaryPhone.replace(REGEX_PHONE_NUMBER, ''),
            givenName: personalInfoReview.firstName,
            surname: personalInfoReview.lastName,
            address: {
              streetAddress: shippingAddress.street1!,
              extendedAddress: shippingAddress.street2!,
              locality: shippingAddress.city!,
              postalCode: shippingAddress.postalCode!,
              region: shippingAddress.stateProv!,
              countryCode: shippingAddress.country!
            },
            onPaymentStart: (data, continueCallback) => {
              iDealDataPayment = data;
              continueCallback();
            }
          });
          await apiClient.savePaymentId(iDealDataPayment!.paymentId);
        } catch (error) {
          console.error(error);
          localPayment.closeWindow();
          throw error;
        }
      }
    };
  });

export interface VirtualWalletStore extends Instance<typeof VirtualWalletStore> {}
