import { Instance, flow, types } from 'mobx-state-tree';

import {
  EnrollmentPaymentType,
  PaymentMethodProviderType,
  ThreeDSecureConfigViewModel
} from '../../../../../external/shared/api/EnrollmentClient.generated';
import { Braintree3dsStore } from './braintree-3ds/Braintree3dsStore';
import { Cybersource3dsStore } from './cybersource-3ds/Cybersource3dsStore';
import { Chase3dsStore } from './chase-3ds/Chase3dsStore';
import { Stripe3dsStore } from './stripe-3ds/Stripe3dsStore';
import { Nuvei3dsStore } from './nuvei-3ds/Nuvei3dsStore';
import { getRootStore } from '../../../../root/RootStoreUtils';
import { apiClient } from '../../../model/credit-card/CreditCard';

export const ThreeDSecureEntryStore = types
  .model({
    config: types.frozen<ThreeDSecureConfigViewModel>(),
    braintree3ds: types.optional(Braintree3dsStore, {}),
    cybersource3ds: types.optional(Cybersource3dsStore, {}),
    chase3ds: types.optional(Chase3dsStore, {}),
    nuvei3ds: types.optional(Nuvei3dsStore, {}),
    stripe3ds: types.optional(Stripe3dsStore, {})
  })
  .views(self => ({
    isProviderEnabled(provider: PaymentMethodProviderType, zeroAuthSupported: boolean): boolean {
      const rootStore = getRootStore(self);
      const { appliedPaymentsReview, billingEntry } = rootStore.moduleStores;
      const { addCard3dsEnabled, multiple3dsEnabled } = self.config;

      const {
        paymentMethodProviderType = billingEntry?.creditCardData.paymentMethodProviderType,
        paymentType = billingEntry?.selectedPaymentType
      } = appliedPaymentsReview ?? {};

      const stepMatches = (addCard3dsEnabled && zeroAuthSupported) || rootStore.stepsManager.isLastStep;

      const gatewayMatches = !multiple3dsEnabled || paymentMethodProviderType === provider;

      return stepMatches && gatewayMatches && paymentType === EnrollmentPaymentType.CreditCard;
    },
    get isEnabled() {
      return (
        self.braintree3ds.isEnabled ||
        self.cybersource3ds.isEnabled ||
        self.chase3ds.isEnabled ||
        self.nuvei3ds.isEnabled ||
        self.stripe3ds.isEnabled
      );
    }
  }))
  .actions(self => ({
    auth: flow(function* auth(): any {
      const { braintree3ds, cybersource3ds, chase3ds, nuvei3ds, stripe3ds } = self;

      let clientJsonValue: string | undefined;
      if (braintree3ds.isEnabled) {
        clientJsonValue = yield braintree3ds.auth();
      } else if (cybersource3ds.isEnabled) {
        clientJsonValue = yield cybersource3ds.auth();
      } else if (chase3ds.isEnabled) {
        clientJsonValue = yield chase3ds.auth();
      } else if (nuvei3ds.isEnabled) {
        clientJsonValue = yield nuvei3ds.auth();
      } else if (stripe3ds.isEnabled) {
        clientJsonValue = yield stripe3ds.auth();
      }

      const rootStore = getRootStore(self);
      if (rootStore.stepsManager.isLastStep && clientJsonValue) {
        yield apiClient.saveClientJsonValue(clientJsonValue);
      }
      return clientJsonValue;
    })
  }));

export type ThreeDSecureEntryStore = Instance<typeof ThreeDSecureEntryStore>;
