import { flow, Instance, types } from 'mobx-state-tree';
import type {
  Auth3dsParams,
  Auth3dsParamsBase,
  Auth3dsResult,
  CreditCardBase,
  ZeroAuth3dsParams
} from 'yl-nuvei-3ds/YlNuvei3ds';

import { getRootStore } from '../../../../../root/RootStoreUtils';
import { formatAmount } from '../../../../../../reference/Currency';
import { PaymentMethodProviderType } from '../../../../../../external/shared/api/EnrollmentClient.generated';

export const Nuvei3dsStore = types
  .model({})
  .volatile(() => ({
    initPromise: undefined as Promise<typeof import('yl-nuvei-3ds/YlNuvei3ds')> | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const { config, isProviderEnabled } = getRootStore(self).moduleStores.threeDSecureEntry!;
      return config.nuvei3dsEnabled && isProviderEnabled(PaymentMethodProviderType.Nuvei, true);
    }
  }))
  .actions(self => ({
    prepareBaseParams() {
      const rootStore = getRootStore(self);
      const { billingEntry, appliedPaymentsReview, shippingAndBillingReview, personalInfoReview } =
        rootStore.moduleStores;

      const currencyCode = rootStore.reference.currencies[0].code;
      const { enrollmentId } = rootStore.enrollmentStatus;

      const creditCardData = billingEntry?.creditCardData ?? appliedPaymentsReview!.creditCardData;
      const billingAddress = billingEntry?.addressForBilling ?? shippingAndBillingReview!.billingAddress;
      const shippingAddress = billingEntry?.userShippingAddress ?? shippingAndBillingReview!.userShippingAddress;
      const email = billingEntry?.userEmail ?? personalInfoReview!.email;
      const phoneNumber = billingEntry?.userPhoneNumber ?? personalInfoReview!.primaryPhone;

      const auth3dsParamsBase: Auth3dsParamsBase = {
        customerId: enrollmentId,
        currencyCode,
        paymentMethodId: creditCardData.identity!,
        email,
        phoneNumber,
        billingAddress: {
          recipient: billingAddress.recipient!,
          phoneNumber: billingAddress.phoneNumber!,
          street1: billingAddress.street1!,
          street2: billingAddress.street2!,
          street3: billingAddress.street3,
          city: billingAddress.city,
          stateProv: billingAddress.stateProv!,
          postalCode: billingAddress.postalCode!,
          countryIsoCode2: billingAddress.country!
        },
        shippingAddress: {
          recipient: shippingAddress.recipient!,
          phoneNumber: shippingAddress.phoneNumber!,
          street1: shippingAddress.street1!,
          street2: shippingAddress.street2!,
          street3: shippingAddress.street3,
          city: shippingAddress.city,
          stateProv: shippingAddress.stateProv!,
          postalCode: shippingAddress.postalCode!,
          countryIsoCode2: shippingAddress.country!
        }
      };

      const creditCardBase: CreditCardBase = {
        expMonth: creditCardData.expirationMonth!,
        expYear: creditCardData.expirationYear!,
        nameOnCard: creditCardData.name!
      };

      return { auth3dsParamsBase, creditCardBase };
    },
    prepareAuthParams(): Auth3dsParams {
      const { auth3dsParamsBase, creditCardBase } = this.prepareBaseParams();

      const rootStore = getRootStore(self);
      const { appliedPaymentsReview, orderSummary } = rootStore.moduleStores;
      const { tokenExToken } = appliedPaymentsReview!.creditCardData;

      return {
        ...auth3dsParamsBase,
        creditCard: {
          ...creditCardBase,
          tokenExToken: tokenExToken!
        },
        amount: formatAmount(appliedPaymentsReview!.amount, 2),
        externalReferenceId: orderSummary!.externalReferenceId,
        isSubscription: orderSummary!.isErEnrollment
      };
    },
    prepareZeroAuthParams(): ZeroAuth3dsParams {
      const { auth3dsParamsBase, creditCardBase } = this.prepareBaseParams();

      const rootStore = getRootStore(self);
      const { billingEntry } = rootStore.moduleStores;
      const { encryptedCardNumber, encryptedCvv } = billingEntry!.creditCardData.encryptedParameters!;

      return {
        ...auth3dsParamsBase,
        creditCard: {
          ...creditCardBase,
          encryptedCvv,
          encryptedCreditCardNumber: encryptedCardNumber!
        }
      };
    }
  }))
  .actions(self => ({
    async initAuth(): Promise<void> {
      // eslint-disable-next-line import/no-unresolved
      self.initPromise ??= import('yl-nuvei-3ds/YlNuvei3ds');
      await self.initPromise;

      const rootStore = getRootStore(self);
      if (rootStore.stepsManager.isLastStep) {
        const { auth3dsWarmUp } = await self.initPromise;
        await auth3dsWarmUp(self.prepareAuthParams());
      }
    }
  }))
  .actions(self => ({
    auth: flow(function* auth(): any {
      const rootStore = getRootStore(self);

      yield self.initAuth();
      const { auth3ds, zeroAuth3ds }: typeof import('yl-nuvei-3ds/YlNuvei3ds') = yield self.initPromise!;

      const result: Auth3dsResult = rootStore.stepsManager.isLastStep
        ? yield auth3ds(self.prepareAuthParams(), { styleClassNames: { modalBackdrop: 'd-none' } })
        : yield zeroAuth3ds(self.prepareZeroAuthParams(), { styleClassNames: { modalBackdrop: 'd-none' } });

      if (!result.success) {
        throw new Error(`Unsuccessful Nuvei 3DS Auth: ${result.clientJsonValue}`);
      }
      return result.clientJsonValue;
    })
  }));

export interface Nuvei3dsStore extends Instance<typeof Nuvei3dsStore> {}
