import { addDisposer, flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms';
import { when } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { required } from '../../../customer-information/shared/validators/RequiredValidator';
import { luhnValidator } from '../../components/credit-card/validators/validate-luhn';
import { combineFields } from '../../../customer-information/shared/validators/ValidateCombinedFields';
import {
  combineFieldDates,
  futureMonthValidator
} from '../../../customer-information/shared/validators/DateInFutureValidator';
import {
  CreditCardClient,
  CreditCardConfigViewModel,
  CreditCardFormModel,
  PaymentMethodProviderType
} from '../../../../external/shared/api/EnrollmentClient.generated';
import { replaceUnallowedCharacters } from '../../../../infrastructure/forms/InputTextFormatter';
import { matchesRegExp } from '../../../customer-information/shared/validators/RegularExpressionValidator';
import { enrollmentClient } from '../../../../infrastructure/http/EnrollmentResource';
import { AsyncPaymentStore } from '../../components/credit-card/three-d-secure/AsyncPaymentStore';
import { getRootStore } from '../../../root/RootStoreUtils';

const cvvRegEx = '^[0-9]+$';
const cvvUnallowedRegEx = '[^0-9]';

export const apiClient = new CreditCardClient('', enrollmentClient);

async function initialize(tokenExPublicKey: string) {
  const jsEncryptPromise = import('jsencrypt');
  const { JSEncrypt } = await jsEncryptPromise;
  const encrypter = new JSEncrypt();
  encrypter.setPublicKey(tokenExPublicKey);
  return { encrypter };
}

let initPromise: ReturnType<typeof initialize> | undefined;

export const CreditCard = types
  .model({
    asyncPayment: types.optional(AsyncPaymentStore, () => AsyncPaymentStore.create()),
    displayOnly: types.optional(types.boolean, false),
    identity: types.maybe(types.string),
    name: types.maybe(types.string),
    number: types.maybe(types.string),
    obfuscatedNumber: types.maybe(types.string),
    cvv: types.maybe(types.string),
    expirationMonth: types.maybe(types.number),
    expirationYear: types.maybe(types.number),
    tokenExToken: types.maybe(types.string),
    validationTransactionId: types.maybe(types.string),
    encryptedParameters: types.maybe(types.frozen<{ encryptedCardNumber: string; encryptedCvv?: string }>()),
    paymentMethodProviderType: types.maybe(
      types.enumeration<PaymentMethodProviderType>(Object.values(PaymentMethodProviderType))
    )
  })
  .views(self => ({
    get config(): CreditCardConfigViewModel {
      return getRootStore(self).moduleStores.billingEntry!.creditCardConfig;
    },
    get bin() {
      return self.obfuscatedNumber?.substring(0, 6);
    },
    get cardType() {
      return this.config.cardTypes.find(x => new RegExp(x.regex).test(this.reactForm.$.number.$));
    },
    get hasUserInput() {
      return !!Object.values(this.reactForm.$).find(x => x.dirty);
    },
    get reactForm() {
      const expirationYearField = new FieldState(self.expirationYear || '').validators(required());
      const fields: { [key: string]: FieldState<any> } = {
        name: new FieldState(self.name || '').validators(required()),
        number: new FieldState(self.number || self.obfuscatedNumber || '')
          .validators(required(), luhnValidator())
          .disableAutoValidation(),
        expirationMonth: new FieldState(self.expirationMonth || '').validators(
          required(),
          combineFields([expirationYearField], combineFieldDates, futureMonthValidator())
        ),
        expirationYear: expirationYearField
      };

      if (this.config.requireCvv) {
        fields.cvv = new FieldState(self.cvv || '')
          .validators(required(), matchesRegExp(cvvRegEx))
          .disableAutoValidation();
      }
      return new FormState(fields);
    },
    canProceed() {
      if (self.displayOnly) {
        return true;
      }
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    applyFormat: flow(function* debounceFormat(field: FieldState<any>) {
      yield new Promise(r => setTimeout(r, 1000));
      if (self.displayOnly) {
        return;
      }
      if (field === self.reactForm.$.cvv) {
        replaceUnallowedCharacters(field, cvvUnallowedRegEx);
      }
    }),
    resolveGateway: flow(function* resolveGateway() {
      self.paymentMethodProviderType ??= yield apiClient.resolveNextGateway(self.cardType?.name);
    }),
    validateCreditCardAsync: flow(function* validateCreditCardAsync() {
      const { transactionId, paymentMethodProviderType } = yield self.asyncPayment.validateCreditCardAsync();
      self.validationTransactionId = transactionId;
      self.paymentMethodProviderType = paymentMethodProviderType;
    }),
    prepareResults: flow(function* prepareResults() {
      if (self.displayOnly) {
        return Promise.resolve(true);
      }
      yield self.reactForm.validate();

      if (self.reactForm.hasError) {
        return false;
      }

      const form = getFormValues(self.reactForm);
      self.cvv = form.cvv;
      self.expirationMonth = form.expirationMonth;
      self.expirationYear = form.expirationYear;
      self.name = form.name;
      self.number = form.number;

      const firstSix = self.number!.substring(0, 6);
      const lastFour = self.number!.substring(self.number!.length - 4, self.number!.length);
      self.obfuscatedNumber = `${firstSix}${'*'.repeat(self.number!.length - 10)}${lastFour}`;

      initPromise ??= initialize(self.config.tokenExPublicKey);
      const { encrypter }: Awaited<typeof initPromise> = yield initPromise;

      const encryptedCardNumber = encrypter.encrypt(form.number);
      self.encryptedParameters = self.config.requireCvv
        ? { encryptedCardNumber, encryptedCvv: encrypter.encrypt(form.cvv) }
        : { encryptedCardNumber };

      self.identity = uuidv4();

      return true;
    }),
    getDataToSubmit() {
      return {
        ...self.encryptedParameters,
        identity: self.identity,
        obfuscatedAccountNumber: self.obfuscatedNumber,
        expMonth: self.expirationMonth,
        expYear: self.expirationYear,
        nameOnCard: self.name,
        paymentMethodProviderType: self.paymentMethodProviderType,
        validationPaymentTransactionId: self.validationTransactionId
      } as CreditCardFormModel & { encryptedCvv: string | undefined };
    },
    handleSaveResponse() {
      self.displayOnly = true;
    }
  }))
  .actions(self => ({
    afterCreate() {
      addDisposer(
        self,
        when(
          () => !initPromise && !self.displayOnly && self.hasUserInput,
          () => (initPromise ??= initialize(self.config.tokenExPublicKey))
        )
      );
    }
  }));

export type CreditCard = Instance<typeof CreditCard>;
