import { Instance, types, flow, applySnapshot } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { getRootStore } from '../../root/RootStoreUtils';


export const PromoVoucherEntryStore = types
  .model({
    voucherCode: types.maybe(types.number),
    showModal: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        voucherCode: new FieldState('').validators(required()).disableAutoValidation()
      });
    }
  }))
  .actions(self => ({
    validateAndApplyPromoVoucherCode: flow(function* validateAndApplyPromoVoucherCode() {
      yield self.reactForm.validate();
      if (self.reactForm.hasError) return;
    
      const response = yield enrollmentResource.get(
        `/validate-and-apply-voucher/${self.reactForm.$.voucherCode.value}`
      );

      // update AppliedPaymentsReview
      const rootStore = getRootStore(self);
      if (rootStore.moduleStores.appliedPaymentsReview != null) {
        applySnapshot(rootStore.moduleStores.appliedPaymentsReview, response.data.appliedPaymentsReview);
      }

      self.reactForm.$.voucherCode.value = '';
      self.showModal = !response.data.available;
    }),
    hideModal() {
      self.showModal = false;
    }
  }))
  .named('PromoVoucherEntryStore');

export type PromoVoucherEntryStore = Instance<typeof PromoVoucherEntryStore>;
