import { Instance, types, flow } from 'mobx-state-tree';
import { PaymentStatusViewModel } from './models/OrderPaymentStatus';
import { enrollmentResource } from '../../infrastructure/http/EnrollmentResource';
import { AuthenticationToken, EnrollmentPaymentType } from '../../external/shared/api/EnrollmentClient.generated';
import {
  CompletionAnalyticsEventData,
  publishECommerceCompletionEvent
} from '../../infrastructure/analytics/gtm-support';
import { AxiosResponse } from 'axios';
import { AgreementKind } from '../customer-information/agreement/model/AgreementKind';
import { getRootStore } from '../root/RootStoreUtils';
import { LogStep } from '../enrollment-process/steps-management/LogStep';
import { processPlaceToPay } from '../billing/components/place-to-pay/Placetopay';
import { Redirector } from '../../infrastructure/redirecting/Redirector';
import { setupVoAuthentication } from '../authentication/VoAuthentication';
import { removeCurrentEnrollmentId } from '../customer-enrollment-status/EnrollmentStatusManager';
import { CreditCard } from '../billing/model/credit-card/CreditCard';
export const CompleteEnrollmentStore = types
  .model({
    reviewPageStepNumber: types.integer,
    editPaymentStepNumber: types.integer,
    paymentErrorModal: false,
    agreementModal: false,
    exceliaAgreementInfoModal: false,
    activationInProgress: false,
    enrollmentAgreementInfoModal: false
  })
  .views(self => ({
    getDataToSendToExcelia(agreementType: string, creditCardInfo?: CreditCard) {
      return {
        creditCardInfo: creditCardInfo
          ? {
              obfuscatedAccountNumber: creditCardInfo.obfuscatedNumber,
              nameOnCard: creditCardInfo.name,
              expMonth: creditCardInfo.expirationMonth,
              expYear: creditCardInfo.expirationYear
            }
          : {},
        needsAcceptAndAuth: false,
        agreementKinds: [agreementType],
        customerLegacyId: 0
      };
    }
  }))
  .actions(self => ({
    activateModal() {
      self.agreementModal = true;
    },
    showExceliaInfoModal() {
      self.exceliaAgreementInfoModal = true;
    },
    showERInfoModal() {
      self.enrollmentAgreementInfoModal = true;
    },

    hideExceliaInfoModal() {
      self.exceliaAgreementInfoModal = false;
    }
  }))
  .actions(self => ({
    closePaymentErrorModalAndRedirect: flow(function* closePaymentErrorModalAndRedirect(): any {
      self.activationInProgress = false;
      self.paymentErrorModal = false;
      yield getRootStore(self).stepsManager.goToStep(self.editPaymentStepNumber);
    }) as () => Promise<void>,
    sendExceliaData: flow(function* sendExceliaData(exceliaToSendform) {
      return yield enrollmentResource.post('save-excelia-agreement', exceliaToSendform);
    }),
    requestErAgreement: flow(function* requestErAgreement(creditCardInfo: CreditCard) {
      return yield enrollmentResource.post('generate-excelia-agreement', {
        commercialAgreementAccepted: true,
        obfuscatedAccountNumber: creditCardInfo.obfuscatedNumber,
        expireDate: `${creditCardInfo.expirationMonth}/${creditCardInfo.expirationYear}`,
        accountName: creditCardInfo.name
      });
    }),
    activateAndCheckout: flow(function* activateAndCheckout(): any {
      const rootStore = getRootStore(self);

      if (self.activationInProgress) {
        return;
      }
      self.activationInProgress = true;
      const paymentType = rootStore.moduleStores.appliedPaymentsReview?.paymentType;
      const {
        earlyEnrollment,
        orderReturnPolicyReview,
        threeDSecureEntry,
        virtualWallet,
        privacyPolicyAgreementEntry,
        exceliaAgreementEntry,
        memberAgreementOrderReview,
        orderReviewAgreementEnabled
      } = rootStore.moduleStores;

      if (threeDSecureEntry?.cybersource3ds.isEnabled && !orderReturnPolicyReview!.haveAcceptedOrderReview()) {
        self.activationInProgress = false;
        orderReturnPolicyReview!.showModal();
        return;
      }

      if (
        (privacyPolicyAgreementEntry && !privacyPolicyAgreementEntry.agreementAccepted) ||
        (exceliaAgreementEntry && !exceliaAgreementEntry.agreementAccepted)
      ) {
        self.activationInProgress = false;
        self.agreementModal = true;
        return;
      }
      if (orderReviewAgreementEnabled && !memberAgreementOrderReview?.haveAcceptedMemberAgreementOrderReview()) {
        self.activationInProgress = false;
        return;
      }
      rootStore.spin();

      try {
        try {
          if (virtualWallet.isEnabled) {
            yield virtualWallet.auth();
          } else if (threeDSecureEntry?.isEnabled) {
            yield threeDSecureEntry.auth();
          }
        } catch (error) {
          throw new PaymentError(error);
        }

        const stepsManager = rootStore.stepsManager;
        if (!stepsManager.isAccordionLayout && earlyEnrollment) {
          const saveResponse: boolean = yield stepsManager.currentStep.saveModuleStates();
          if (!saveResponse) {
            self.activationInProgress = false;
            return;
          }
        }

        const {
          data: { paymentRedirectUrl, pendingPageUrl, confirmationPageUrl, shouldPoll, token, analyticsEventData }
        }: AxiosResponse<EnrollmentCompletionViewModel> = yield enrollmentResource.get('/complete-enrollment');

        let paymentStatus: PaymentStatusViewModel | undefined;

        if (paymentRedirectUrl) {
          if (paymentType === EnrollmentPaymentType.Placetopay) {
            try {
              paymentStatus = yield processPlaceToPay(paymentRedirectUrl);
            } catch (error) {
              throw new PaymentError(error, analyticsEventData);
            }
          }
        } else if (shouldPoll) {
          try {
            const orderId = rootStore.enrollmentStatus.enrollmentId;
            const response: AxiosResponse<PaymentStatusViewModel> = yield enrollmentResource.get(
              `order-payment-status/${orderId}`
            );
            paymentStatus = response.data;
          } catch (error) {
            // We don't crash if we aren't able to get any status when the server is down or there are connection issues.
            // After a while the user will get redirected to the pending page if we have not received actual status from the server
          }
        }

        if (paymentStatus?.paymentStatus === 'Failed') {
          throw new PaymentError(null, analyticsEventData);
        }

        setupVoAuthentication(paymentStatus?.token ?? token);
        yield LogStep.logStepProgress('signupConfirmation');
        removeCurrentEnrollmentId();

        if (earlyEnrollment) {
          publishECommerceCompletionEvent('early-enrollment-completed', analyticsEventData);
          Redirector.redirect(confirmationPageUrl);
        } else if (paymentStatus?.paymentStatus === 'Successful') {
          publishECommerceCompletionEvent('successful-payment', analyticsEventData);
          Redirector.redirect(confirmationPageUrl);
        } else {
          publishECommerceCompletionEvent('pending-payment', analyticsEventData);
          if (paymentRedirectUrl && paymentType !== EnrollmentPaymentType.Placetopay) {
            Redirector.redirect(paymentRedirectUrl);
          } else {
            Redirector.redirect(pendingPageUrl);
          }
        }
      } catch (error) {
        if (error instanceof PaymentError) {
          publishECommerceCompletionEvent('failed-payment', error.analyticsEventData);
          self.paymentErrorModal = true;
        } else {
          publishECommerceCompletionEvent('failed-request');
          throw error;
        }
      } finally {
        rootStore.unspin();
      }
    }) as () => Promise<void>
  }))
  .actions(self => ({
    async acceptAgreement(agreementKind: AgreementKind): Promise<any> {
      const rootStore = getRootStore(self);
      self.agreementModal = false;
      const enrollmentStep = rootStore.stepsManager.currentStep;

      switch (agreementKind) {
        case 'PrivacyPolicy': {
          const privacyPolicyAgreementEntry = rootStore.moduleStores.privacyPolicyAgreementEntry!;

          privacyPolicyAgreementEntry.setAgreementAccepted();
          const saveSucceeded = await enrollmentStep.saveModuleStates();
          if (saveSucceeded) {
            self.activateAndCheckout();
          }
          break;
        }

        case 'Excelia': {
          rootStore.spin();
          try {
            const exceliaAgreementEntry = rootStore.moduleStores.exceliaAgreementEntry!;
            const orderSummary = rootStore.moduleStores.orderSummary!;
            const appliedPaymentsReview = rootStore.moduleStores.appliedPaymentsReview!;

            const exceliaToSendform = !exceliaAgreementEntry.documentUpdatedToEr
              ? self.getDataToSendToExcelia('Member')
              : self.getDataToSendToExcelia('ER', appliedPaymentsReview.creditCardData);
            exceliaToSendform.customerLegacyId = exceliaAgreementEntry.customerLegacyId;

            const exceliaResult = await self.sendExceliaData(exceliaToSendform);

            if (exceliaResult) {
              if (orderSummary?.isErEnrollment && !exceliaAgreementEntry.documentUpdatedToEr) {
                const erAgreement = await self.requestErAgreement(appliedPaymentsReview.creditCardData);
                exceliaAgreementEntry.downloadDocument();
                exceliaAgreementEntry.updateModel(erAgreement.data, true);
                rootStore.unspin();
                self.activateModal();
                break;
              } else if (orderSummary?.isErEnrollment && exceliaAgreementEntry.documentUpdatedToEr) {
                self.showExceliaInfoModal();
              }

              exceliaAgreementEntry.setAgreementAccepted();
            }

            exceliaAgreementEntry.downloadDocument();

            const saveSucceeded = await enrollmentStep.saveModuleStates();
            if (saveSucceeded) {
              rootStore.unspin();
              self.activateAndCheckout();
              break;
            }
            rootStore.unspin();
            break;
          } catch (e) {
            rootStore.unspin();
            break;
          }
        }
        default: {
          break;
        }
      }
    },
    dismissAgreement() {
      self.agreementModal = false;
    },
    activateAgreement(): any {
      self.agreementModal = true;
      return true;
    }
  }));

export type CompleteEnrollmentStore = Instance<typeof CompleteEnrollmentStore>;

class PaymentError extends Error {
  constructor(readonly innerError?: any, readonly analyticsEventData?: CompletionAnalyticsEventData) {
    super();
  }
}

export interface EnrollmentCompletionViewModel {
  paymentRedirectUrl: string;
  pendingPageUrl: string;
  confirmationPageUrl: string;
  token: AuthenticationToken;
  analyticsEventData: CompletionAnalyticsEventData;
  shouldPoll: boolean;
}
